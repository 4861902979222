









import ChurnTable from "@/components/churn/ChurnTable.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    ChurnTable,
    TheLayoutPortal,
    MHeader
  }
})
export default class ChurnView extends mixins(PartnerFallbackMixin) {
  async mounted() {
    this.trySetByRouteOrDefault();
  }
}
