var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "predefinedFilter": _vm.predefinedFilter
    },
    scopedSlots: _vm._u([{
      key: "item.timestamp.created",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.timestamp.createdReadable) + " ")];
      }
    }, {
      key: "item.user.churnRateLastYear",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s((item.user.churnRateLastYear * 100).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })) + "% ")];
      }
    }, {
      key: "item.user.churnRate",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s((item.user.churnRate * 100).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })) + "% ")];
      }
    }, {
      key: "item.thg.churnRateLastYear",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_vm._v(" " + _vm._s((item.thg.churnRateLastYear * 100).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })) + "% ")];
      }
    }, {
      key: "item.thg.churnRate",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_vm._v(" " + _vm._s((item.thg.churnRate * 100).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })) + "% ")];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }