/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ThgHttpClientProvider } from "../thg-http-client.provider";
import { Churn } from "../v1/Churn";
import {
  ThgChurnControllerGetAllPaginatedParamsGen,
  ThgChurnViewModelGen,
  ThgPageViewModelGen
} from "../v1/data-contracts";

/**
 * Service for interacting with the churn endpoint.
 */
export class ChurnService {
  private readonly churn: Churn;

  constructor(clientProvider: ThgHttpClientProvider) {
    this.churn = new Churn(clientProvider.client());
  }

  /**
   * Retrieves all churn records
   *
   * @param query - Parameters for retrieving churn data.
   * @returns A paginated view model containing churn data.
   */
  async findAll(
    query: ThgChurnControllerGetAllPaginatedParamsGen
  ): Promise<ThgPageViewModelGen & { data?: ThgChurnViewModelGen[] | undefined }> {
    return (await this.churn.churnControllerGetAllPaginated(query)).data;
  }
}

export default new ChurnService(new ThgHttpClientProvider());
