import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { Churn, IChurn } from "@/models/churn.entity";
import churnService from "@/services/thg/services/churnService";
import { ThgChurnControllerGetAllPaginatedParamsGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Module, getModule } from "vuex-module-decorators";
import { PaginatedBaseStore } from "../paginated-base.store";
import { ChurnDataAccessLayer } from "./access-layers/churn.access-layer";
import { PaginationFilterListElement } from "./base-pagination.store";

const ChurnPageDataProvider = new (class extends AbstractPageDataProvider<
  IChurn,
  ThgChurnControllerGetAllPaginatedParamsGen
> {
  itemsPerPage = 25;

  async getPage(query: ThgChurnControllerGetAllPaginatedParamsGen): Promise<IPageViewModel<IChurn>> {
    const res = await churnService.findAll(query);

    const meta = res.meta;
    const data = (res.data ?? []) as IChurn[];

    return { meta, data };
  }
})();

@Module({
  dynamic: true,
  namespaced: true,
  name: "churn",
  store
})
export class ChurnPaginationStore extends PaginatedBaseStore<IChurn, ThgChurnControllerGetAllPaginatedParamsGen> {
  protected _data = ChurnDataAccessLayer;
  protected _pageProvider = ChurnPageDataProvider;
  protected _pager = new PageDataHandler(this._data, this._pageProvider);

  filterOptions: PaginationFilterListElement[] = Churn.filterables;
}

export const ChurnModule = getModule(ChurnPaginationStore);
