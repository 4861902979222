import { AbstractLocalDataAccessLayer } from "@/lib/utility/data/local-data-access-layer.abstract";
import { Churn, IChurn } from "@/models/churn.entity";

export const ChurnDataAccessLayer = new (class extends AbstractLocalDataAccessLayer<IChurn> {
  protected mapEntity(entity: IChurn): IChurn {
    return new Churn(entity);
  }

  getIdentifier(entity: IChurn): string {
    return entity.id;
  }

  mapsConfig = {};
})();
