import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { ThgChurnEntityViewModelGen } from "@/services/thg/v1/data-contracts";

@IsFilterable
class ChurnEntityAnalysisBase implements ThgChurnEntityViewModelGen {
  @FilterConfig({ displayName: "objects.churn.user.processed", type: FilterTypes.NUMBER })
  processed: number;

  @FilterConfig({ displayName: "objects.churn.user.churned", type: FilterTypes.NUMBER })
  churned: number;

  @FilterConfig({ displayName: "objects.churn.user.churnedLastYear", type: FilterTypes.NUMBER })
  churnedLastYear: number;

  @FilterConfig({ displayName: "objects.churn.user.firstTime", type: FilterTypes.NUMBER })
  firstTime: number;

  @FilterConfig({ displayName: "objects.churn.user.total", type: FilterTypes.NUMBER })
  total: number;

  @FilterConfig({
    displayName: "objects.churn.user.totalTargetAndPreviousYear",
    type: FilterTypes.NUMBER
  })
  totalTargetAndPreviousYear: number;

  @FilterConfig({ displayName: "objects.churn.user.churnRate", type: FilterTypes.NUMBER })
  churnRate: number;

  @FilterConfig({
    displayName: "objects.churn.user.churnRateLastYear",
    type: FilterTypes.NUMBER
  })
  churnRateLastYear: number;

  constructor(data?: Partial<ChurnEntityAnalysisBase>) {
    this.processed = data?.processed ?? 0;
    this.churned = data?.churned ?? 0;
    this.churnedLastYear = data?.churnedLastYear ?? 0;
    this.firstTime = data?.firstTime ?? 0;
    this.total = data?.total ?? 0;
    this.totalTargetAndPreviousYear = data?.totalTargetAndPreviousYear ?? 0;
    this.churnRate = data?.churnRate ?? 0;
    this.churnRateLastYear = data?.churnRateLastYear ?? 0;
  }
}

type IChurnEntityAnalysis = ChurnEntityAnalysisBase;
const ChurnEntityAnalysis = Filter.createForClass(ChurnEntityAnalysisBase);

export { ChurnEntityAnalysis, IChurnEntityAnalysis };
