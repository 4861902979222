










































import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { $t } from "@/lib/utility/t";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { MrfiktivPageFilterElementGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ChurnModule } from "@/store/modules/churn.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";

@Component({
  components: {
    PaginatedTable
  }
})
export default class ChurnTable extends mixins(PartnerFallbackMixin, PermissionMixin) {
  readonly store = ChurnModule;

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [];
    headers.push({
      text: $t("objects.churn.year"),
      value: "year",
      width: "75px",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.countryCode"),
      value: "countryCode",
      width: "75px",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.created"),
      value: "timestamp.created",
      width: "100px",
      align: "start"
    });

    headers.push({
      text: $t("objects.churn.user.churnRate"),
      value: "user.churnRate",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.user.churnRateLastYear"),
      value: "user.churnRateLastYear",
      align: "start"
    });

    headers.push({
      text: $t("objects.churn.thg.churnRate"),
      value: "thg.churnRate",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.thg.churnRateLastYear"),
      value: "thg.churnRateLastYear",
      align: "start"
    });

    headers.push({
      text: $t("objects.churn.user.processed"),
      value: "user.processed",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.user.churned"),
      value: "user.churned",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.user.churnedLastYear"),
      value: "user.churnedLastYear",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.user.firstTime"),
      value: "user.firstTime",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.user.total"),
      value: "user.total",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.user.totalTargetAndPreviousYear"),
      value: "user.totalTargetAndPreviousYear",
      align: "start"
    });

    headers.push({
      text: $t("objects.churn.thg.processed"),
      value: "thg.processed",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.thg.churned"),
      value: "thg.churned",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.thg.churnedLastYear"),
      value: "thg.churnedLastYear",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.thg.firstTime"),
      value: "thg.firstTime",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.thg.total"),
      value: "thg.total",
      align: "start"
    });
    headers.push({
      text: $t("objects.churn.thg.totalTargetAndPreviousYear"),
      value: "thg.totalTargetAndPreviousYear",
      align: "start"
    });

    return headers;
  }

  get predefinedFilter(): { name: string; filter: MrfiktivPageFilterElementGen[] }[] {
    const predefinedFilter: { name: string; filter: MrfiktivPageFilterElementGen[] }[] = [
      {
        name: "DE 2026",
        filter: [
          { key: "year", operation: "$eq", value: "2026" },
          { key: "countryCode", operation: "$eq", value: CountryCodeEnum.germany }
        ]
      },
      {
        name: "DE 2025",
        filter: [
          { key: "year", operation: "$eq", value: "2025" },
          { key: "countryCode", operation: "$eq", value: CountryCodeEnum.germany }
        ]
      },
      {
        name: "DE 2024",
        filter: [
          { key: "year", operation: "$eq", value: "2024" },
          { key: "countryCode", operation: "$eq", value: CountryCodeEnum.germany }
        ]
      }
    ];

    return predefinedFilter;
  }
}
