

































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import ThgBillingPlausibilityCheckDialog from "@/components/thg/ThgBillingPlausibilityCheckDialog.vue";
import Card from "@/components/utility/Card.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import ContextMenu from "@/components/utility/ContextMenu.vue";
import Debug from "@/components/utility/Debug.vue";
import TableWrapper, { ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { AffiliateTypeEnum } from "@/lib/enum/AffiliateType.enum";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { NotFoundException } from "@/lib/exceptions/http";
import { handleError } from "@/lib/utility/handleError";
import ThgBillingBatchMixin from "@/mixins/ThgBillingBatchMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { IThg } from "@/models/thg.entity";
import { AdminUser, IAdminUser } from "@/models/user.entity";
import { ThgAffiliateViewmodelGen, ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { AffiliatePortalModule } from "@/store/modules/affiliate.portal.store";
import { BillingBatchModule } from "@/store/modules/billing-batch.store";
import { BillingModule } from "@/store/modules/billing.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import ThgBillingCreditAutoBillDialog from "./ThgBillingAutoBillDialog.vue";

class AdminUserCount extends AdminUser {
  count: number;

  constructor(adminUser: IAdminUser, count: number) {
    super(adminUser);
    this.count = count;
  }
}
@Component({
  components: {
    ThgBillingCreditAutoBillDialog,
    TheLayoutPortal,
    Card,
    ThgBillingPlausibilityCheckDialog,
    ConfirmActionDialog,
    Debug,
    TableWrapper,
    ContextMenu,
    LatestEntriesCardEmpty
  }
})
/**
 * A view where users can select who they want to bill and what kind of billing is to be done
 */
export default class ThgBillingBatchView extends ThgBillingBatchMixin {
  billingType = (this.$route?.params?.billingType as BillingTypeEnum) || BillingTypeEnum.CREDIT;

  /**
   * The selected items
   */
  selected: ((PartnerEntity | IAdminUser) & { count: number })[] = [];
  selectedAffiliatesNotFound: ThgAffiliateViewmodelGen[] = [];
  selectedBatchableBillingTypes: BillingTypeEnum[] = [];
  selectedAffiliatesWithoutUserId: ThgAffiliateViewmodelGen[] = [];

  /**
   * are the items in the table loading
   */
  isTableItemsLoading = true;

  /**
   * Date string used as filter for a date from which on documents for billing are loaded from the backend
   */
  from = "";

  /**
   * Date string used as filter for a date to which on documents for billing are loaded from the backend
   */
  to = "";

  /**
   * the documents that are billed
   */
  items: ((PartnerEntity | IAdminUser) & { count: number })[] = [];

  /**
   * the documents that are billed
   * as of the moments those are ghgs but in the future stuff like meter readings are also gonna be considered
   */
  readyForBilling: IThg[] | ThgThgMeterReadingViewModelGen[] = [];

  /**
   * List of thgs that have an affiliate code that has type other. (we do not want to bill those in case of affiliate billing)
   * This list is filled when we load the affiliate to get the user id of the user that we bill in getAffiliate and reset when the billingType changes and the data is refereshed
   */
  thgIdsWithAffiliateCodeOther: string[] = [];

  /**
   * List of information of affiliates that do not have a user id
   */
  affiliatesWithoutUserId: { id?: string; code?: string; partnerId?: string }[] = [];

  /**
   * List of thgs that have an affilaite code that can not be found
   */
  affiliatesNotFound: { thgId?: string; code?: string; partnerId?: string }[] = [];

  /**
   * List of affiliate documents
   */
  affiliates: ThgAffiliateViewmodelGen[] = [];

  /**
   * The translations
   */
  get i18n() {
    return this.$t("views.ThgBillingBatchView") || {};
  }

  /**
   * Counts how many filters are applied in the context menu with the date filters
   * this is used in the v-badge to make using this component easier
   * if no filter is set the v-badge is hidden
   */
  get filterCount() {
    let count = 0;
    if (this.from) {
      count++;
    }
    if (this.to) {
      count++;
    }
    return count;
  }

  /**
   * Enables a preselection by route
   */
  get billingTypeRoute(): BillingTypeEnum {
    return this.$route.params.billingType as BillingTypeEnum;
  }

  /**
   * The items shown in the table that are shown when creating billings for users
   */
  get userHeaders(): ITableWrapperHeader[] {
    return [
      { text: this.i18n["userName"], value: "userName", type: "string" },
      { text: this.i18n["firstName"], value: "firstName", type: "string" },
      { text: this.i18n["lastName"], value: "lastName", type: "string" },
      { text: this.i18n["count"], value: "count", type: "number" }
    ];
  }

  /**
   * The items shown in the table that are shown when creating partners
   */
  get partnerHeaders(): ITableWrapperHeader[] {
    return [
      { text: this.i18n["companyName"], value: "companyName", type: "string" },
      { text: this.i18n["companyUsername"], value: "companyUsername", type: "string" },
      { text: this.i18n["count"], value: "count", type: "number" }
    ];
  }

  /**
   * The items shown in the table that are shown when creating partners
   */
  get meterReadingHeaders(): ITableWrapperHeader[] {
    return [
      { text: this.i18n["thgId"], value: "id", type: "string" },
      { text: this.i18n["userName"], value: "userName", type: "string" },
      { text: this.i18n["countryCode"], value: "countryCode", type: "string" }
    ];
  }

  /**
   * The items shown in the table that are shown when affilaites without userId were found
   */
  get affiliatesWithoutUserIdHeaders(): ITableWrapperHeader[] {
    return [
      { text: this.i18n["id"], value: "id", type: "string" },
      { text: this.i18n["code"], value: "code", type: "string" },
      { text: this.i18n["partnerId"], value: "partnerId", type: "string" }
    ];
  }

  /**
   * The items shown in the table that are shown when affilaites without userId were found
   */
  get affiliatesNotFoundHeaders(): ITableWrapperHeader[] {
    return [
      { text: this.i18n["thgId"], value: "thgId", type: "string" },
      { text: this.i18n["code"], value: "code", type: "string" },
      { text: this.i18n["partnerId"], value: "partnerId", type: "string" }
    ];
  }

  /**
   * Returns the headers for the table depending on what we bill atm
   */
  get headers(): ITableWrapperHeader[] {
    if (this.getIsUserFocusedBillingType(this.billingType)) {
      return this.userHeaders;
    }
    if (this.getIsPartnerFocusedBillingType(this.billingType)) {
      return this.partnerHeaders;
    }

    return [];
  }

  /**
   * Get the affiliate document
   *
   * @param thgId
   * @param code
   * @param partnerId
   */
  async getAffiliate(thgId: string, code: string, partnerId: string) {
    if (!code) {
      return undefined;
    }

    const affiliate = this.affiliates.find(a => a.code === code && a.partnerId === partnerId);

    // We do not want to bill affiliates that are of type other
    if (affiliate?.type === AffiliateTypeEnum.OTHER) {
      this.thgIdsWithAffiliateCodeOther.push(thgId);

      return undefined;
    }

    return affiliate;
  }

  /**
   * Prepares data when component mounts. per default credit stuff is shown
   */
  mounted() {
    this.updateTableItems();
  }

  /**
   * Depending on what we bill we need to access a specific id from different document types
   * the id refers to a billed entity like a human being or a company -> it is usually referred to as a "billed being" here
   * this method takes the input document (which is a thg but could also be something else in the future depending on the billing type) and extracts the id that we are interested in from it
   */
  async extractReferedIdFromDocument(billingType: BillingTypeEnum, thg: IThg | ThgThgMeterReadingViewModelGen) {
    if (this.getIsAffiliateBillingType(this.billingType)) {
      try {
        const affiliate = await this.getAffiliate(thg.id, thg.code || "", thg.partnerId);

        // collect information on affiliates without id for debugging
        if (!affiliate?.userId) {
          if (
            !this.affiliatesWithoutUserId.find(
              a =>
                a.id === affiliate?.id &&
                a.code === (affiliate?.code ?? thg.code) &&
                a.partnerId === (affiliate?.partnerId ?? thg.partnerId)
            )
          ) {
            this.affiliatesWithoutUserId.push({
              id: affiliate?.id,
              code: affiliate?.code ?? thg.code,
              partnerId: affiliate?.partnerId ?? thg.partnerId
            });
            this.$log.error(`Affiliate ${affiliate?.id} has no userId`);
          }
        }

        return affiliate?.userId || "";
      } catch (e) {
        if (e instanceof NotFoundException) {
          if (
            !this.affiliatesWithoutUserId.find(
              a => a.id === thg?.id && a.code === thg?.code && a.partnerId === thg?.partnerId
            )
          ) {
            this.affiliatesNotFound.push({
              thgId: thg?.id,
              code: thg?.code,
              partnerId: thg?.partnerId
            });
            this.$log.error(e);
          }
        }
        return "";
      }
    }
    if (this.getIsUserFocusedBillingType(billingType)) {
      return thg.userId;
    }
    if (this.getIsPartnerFocusedBillingType(billingType)) {
      return thg.partnerId;
    }
  }

  /**
   * Loads the affiliate documents based on the codes in the given documents
   * @param readyForBilling the documents to load the affiliates for
   */
  async loadAffiliates(readyForBilling: IThg[] | ThgThgMeterReadingViewModelGen[]) {
    let codes: string[] = [];
    readyForBilling.forEach((d: IThg | ThgThgMeterReadingViewModelGen) => {
      const code = d.code;
      if (code) {
        codes.push(code);
      }
    });
    codes = [...new Set(codes)];
    try {
      const affiliates = await AffiliatePortalModule.getManyByCode(codes);
      this.affiliates.splice(0, this.affiliates.length, ...affiliates);
    } catch (e) {
      handleError(e);
    }
  }

  /**
   * Get ids of the documents that are refered to by this billing type mapped to the amount of documents that are related to them
   *
   * @param readyForBilling
   * @returns a map from reference id to count
   */
  async extractIds(readyForBilling: IThg[] | ThgThgMeterReadingViewModelGen[]): Promise<Map<string, number>> {
    const ids: Map<string, number> = new Map();

    if (this.billingType === BillingTypeEnum.AFFILIATE) {
      await this.loadAffiliates(readyForBilling);
    }

    for (const doc of readyForBilling) {
      const id = (await this.extractReferedIdFromDocument(this.billingType, doc)) || "";
      if (id) {
        let count = ids.get(id) || 0;
        count++;

        ids.set(id, count);
      }
    }

    return ids;
  }

  /**
   * get the documents for the ids. those might be user documents or partner documents
   *
   * @param ids map from id to amount id is refered to by ready-for-billing-docs
   */
  async getDocumentsForIds(
    ids: Map<string, number>
  ): Promise<AdminUserCount[] | (PartnerEntity & { count: number })[] | undefined> {
    if (this.getIsUserFocusedBillingType(this.billingType)) {
      const users = (await AdminUserPaginationModule.getUsers([...ids.keys()])) as IAdminUser[] | undefined;
      return users?.map(p => new AdminUserCount(p, ids.get(p._id) || 0));
    }
    if (this.getIsPartnerFocusedBillingType(this.billingType)) {
      await PartnerModule.getPartners([...ids.keys()]);
      return PartnerModule.partners.map(p => {
        return { ...p, id: p._id, count: ids.get(p._id) || 0 }; // the id is the key in the table wrapper
      });
    }
  }

  /**
   * Load thgs that are ready to be billed and load associated partner or user documents depending on billing type
   */
  async updateTableItems() {
    try {
      this.isTableItemsLoading = true;
      this.thgIdsWithAffiliateCodeOther = [];
      this.affiliatesNotFound.splice(0);
      this.affiliatesWithoutUserId.splice(0);

      // parameters to request those documents that are ready for the billing
      const from = this.from ? new Date(this.from) : new Date("2000-01-01");
      const to = this.to ? new Date(this.to) : new Date();

      // request documents that are ready for billing
      this.readyForBilling = [];
      await BillingModule.getReadyForBilling({
        billingType: this.billingType,
        from: from.toISOString(),
        to: to.toISOString()
      });
      const readyForBilling = BillingModule.readyForBilling;
      this.readyForBilling = readyForBilling;

      // the ids of the documents that are realated to the items that are ready for the billing -> partnerIds/ userIds
      const ids = (await this.extractIds(readyForBilling)) || [];
      // get the related documents
      this.items.splice(0);

      if ([...ids.keys()].length > 0) {
        const newItems = (await this.getDocumentsForIds(ids)) || [];

        this.items.splice(0, this.items.length, ...newItems);
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isTableItemsLoading = false;
    }
  }

  /**
   * Set up the environment for the batched billing and navigate to the page for that
   *
   * @param billedBeings the user/partners that are billed
   */
  beginBilling(billedBeings: (PartnerEntity | IAdminUser)[]) {
    let billedDocuments = this.readyForBilling;

    // We do not want to create an affiliate billing for thgs that have an affilaite code of type other
    if (this.getIsAffiliateBillingType(this.billingType)) {
      billedDocuments = (billedDocuments as IThg[]).filter(bd => !this.thgIdsWithAffiliateCodeOther.includes(bd.id));
    }

    BillingBatchModule.setForBilling({
      billedBeings,
      billedDocuments
    });

    this.$router.push({ name: "ThgBillingBatchSelectionItemView", params: { billingType: this.billingType } });
  }
}
